import https from "./https"
import store from "@/store"

const organization = store.state.general.organization

const rewardCardApi = {
  list(params) {
    return https.get(`admin/organizations/${organization.id}/reward-cards`, {params})
  },
  get(rewardCardId) {
    return https.get(`admin/organizations/${organization.id}/reward-cards/${rewardCardId}`)
  },
  store (params) {
    return https.post(`admin/organizations/${organization.id}/reward-cards`, params)
  },
  update(params) {
    return https.put(`admin/organizations/${organization.id}/reward-cards/${params.id}`, params)
  },
  remove(rewardCardId) {
    return https.delete(`admin/organizations/${organization.id}/reward-cards/${rewardCardId}`)
  },
}

export default rewardCardApi
